<template>
  <div class="fd-app-mural-componente">
    <div class="d-flex justify-content-center">
      <h4>
        Mural de Avisos
      </h4>
    </div>
    <ul v-if="fastMuralLoading">
      <li class="text-center py-3">
        <a @click.prevent href="#">
          <span>Carregando </span>
          <b-icon-gear-fill class="text-orange" animation="spin-pulse" font-scale="1"/>
        </a>
      </li>
    </ul>
    <ul v-else-if="fastMural.length">
      <li
        v-for="m in fastMural"
        :key="m.id_mural"
        class="row justify-content-between mx-3"
      >
        <a
          :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/mural'"
          @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/mural')"
        >
          <b-icon-circle-fill font-scale="0.65" class="text-orange pb-1"/> {{ m.titulo_publicacao }}
        </a>
        <small>{{ formataDataT(m.data_publicacao) }}</small>
      </li>
    </ul>
    <ul v-else>
      <li class="text-center py-3">
        <a @click.prevent href="#">Nenhum aviso <b-icon-chat-left-dots/></a>
      </li>
    </ul>
    <div class="text-center my-3">
      <a
        class="btn-novo btn-primary my-3 text-light"
        :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/mural'"
        @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/mural')"
      >Acessar</a>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "MuralAvisos",
  mixins: [methods],
  data: function () {
    return {
      fastMural: [],
      fastMuralLoading: true
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.$store.state.fastPermissoes.id_plataforma = this.$route.params.id_plataforma;
      this.getMuralPaginado();
    }
  },
  methods: {
    async getMuralPaginado() { 
      try {
        let resp = await fetch(
          settings.endApiFastEad +
          `api/fast_mural/lista_mural_paginado_nao_visualizado?PageIndex=1&PageSize=3&IdPlataforma=${this.$route.params.id_plataforma}&IdPessoa=${this.$store.state.fastPermissoes.id_pessoa}`,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();  
        this.fastMuralLoading = false  
        if (json.TotalResults) {
          this.fastMural = json.Data
        }       
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeDataPublicacao(mural) {
      let dataPublicacao =
        "Publicado em " +
        mural.split("T")[0] +
        " às " +
        mural.split("T")[1].split(".")[0];
      return dataPublicacao.replace(/-/g, "/");
    },
  },
};
</script>

<style></style>
